import {
  START_FETCHING_RESTAURANTS,
  RECEIVE_RESTAURANTS,
  SELECT_RESTAURANT,
} from "../constants";

const INITIAL_STATE = {
  isLoading: true,
  isError: false,
  restaurants: [],
  selectedRestaurant: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_FETCHING_RESTAURANTS:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_RESTAURANTS:
      return {
        ...state,
        isLoading: false,
        isError: action.payload.isError,
        restaurants: action.payload,
      };
    case SELECT_RESTAURANT:
      return {
        ...state,
        selectedRestaurant: action.payload.selectedRestaurant,
        isSelectedFromList: action.payload.isSelectedFromList,
      };
    default:
      return state;
  }
};

export default reducer;
