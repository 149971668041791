import React from "react";
import { TimePicker } from "antd";

import { SET_FILTER_TIME } from "../../redux/constants";

import "./style.css";
import { useSelector, useDispatch } from "react-redux";

const Filter = () => {
  const currentTimePacific = useSelector((state) => state.filters.filterTime);
  const dispatch = useDispatch();

  const onFilterChange = (time) => {
    dispatch({ type: SET_FILTER_TIME, payload: { filterTime: time } });
  };

  return (
    <div className="filter-container">
      <p className="time-picker-header">Time</p>
      <div className="time-picker-container">
        <TimePicker
          allowClear={false}
          bordered={false}
          onChange={(time) => onFilterChange(time)}
          use12Hours={true}
          minuteStep={5}
          format={"h:mm a"}
          defaultValue={currentTimePacific}
          inputReadOnly={true}
          getPopupContainer={(trigger) => trigger.parentElement}
        />
      </div>
    </div>
  );
};

export default Filter;
