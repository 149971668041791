import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer.js";
import {
  START_FETCHING_RESTAURANTS,
  RECEIVE_RESTAURANTS,
  CACHED_RESTAURANTS_LOCAL_STORAGE,
  CACHED_RESTAURANTS_TIMESTAMP,
} from "./constants";
import { API } from "aws-amplify";
import * as AWS from "aws-sdk";

const middleware = applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);

store.dispatch((dispatch) => {
  dispatch({ type: START_FETCHING_RESTAURANTS });
  const apiName = "restaurants";
  const path = "/restaurants";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      charset: "UTF-8",
    },
  };
  const oneDayInMs = 86400000;

  const cachedRestaurants = localStorage.getItem(
    CACHED_RESTAURANTS_LOCAL_STORAGE
  );
  const cachedRestaurantsTimestamp = localStorage.getItem(
    CACHED_RESTAURANTS_TIMESTAMP
  );
  const dateNow = Date.now();
  if (
    !cachedRestaurants ||
    !cachedRestaurantsTimestamp ||
    dateNow - cachedRestaurantsTimestamp > oneDayInMs
  ) {
    API.get(apiName, path, myInit)
      .then((response) => {
        const respo = response.Items.map((item) =>
          AWS.DynamoDB.Converter.unmarshall(item)
        );
        localStorage.setItem(
          CACHED_RESTAURANTS_LOCAL_STORAGE,
          JSON.stringify(respo)
        );
        localStorage.setItem(CACHED_RESTAURANTS_TIMESTAMP, dateNow);
        dispatch({ type: RECEIVE_RESTAURANTS, payload: respo, isError: false });
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        // dispatch({ type: RECEIVE_RESTAURANTS, payload: [], isError: true });
      });
  } else {
    dispatch({
      type: RECEIVE_RESTAURANTS,
      payload: JSON.parse(cachedRestaurants),
      isError: false,
    });
  }

  // BELOW IS FETCHING DATA VIA DYNAMO DIRECTLY, PREFERRED TO FETCH VIA API ABOVE
  // fetchData("HappyBite").then((response, error) => {
  //   if (error) {
  //     console.log("Error when retrieving Restaurants");
  //     console.log(error);
  //     // dispatch({ type: RECEIVE_RESTAURANTS, payload: [], isError: true });
  //   } else {
  //     console.log("Fetched restaurants successfully");
  //     console.log(response)
  //     // dispatch({ type: RECEIVE_RESTAURANTS, payload: response, isError: false });
  //   }
  // });
});

export default store;
