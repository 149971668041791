import React, { useState } from "react";
import { useSelector } from "react-redux";
import { List, Skeleton, Divider, Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import ListItem from "./ListItem";
import Filter from "../Filter";
import { isClosingWithinHour, isHappyHourNow } from "../../utils/timeUtils";
import useWindowDimensions from "hooks/useWindowDimensions";

import "./style.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const fullHeight = "100%";
const partialHeight = "35%";
const noHeight = "0%";

const SideList = () => {
  const { height, width } = useWindowDimensions();
  const restaurants = useSelector((state) => state.restaurants.restaurants);
  const isLoading = useSelector((state) => state.restaurants.isLoading);
  const isError = useSelector((state) => state.restaurants.isError);
  const selectedRestaurant = useSelector(
    (state) => state.restaurants.selectedRestaurant
  );
  const currentTimePacific = useSelector((state) => state.filters.filterTime);
  const [mobileViewHeight, setMobileViewHeight] = useState(partialHeight);
  const currentDay = new Date().getDay();

  // Converting Boolean -> Number in order to compare with sort function
  // Number(true) -> 1, Number(false) -> 0
  // Sorting over two properties:
  // 1) Ensure Happy hour locations are first
  // 2) Ensure Closing within one hour are second
  const sortedRestaurantsByClosingTime = restaurants.sort(
    (a, b) =>
      Number(isHappyHourNow(currentTimePacific, b.times[currentDay])) -
        Number(isHappyHourNow(currentTimePacific, a.times[currentDay])) ||
      Number(!isClosingWithinHour(b.times[currentDay], currentTimePacific)) -
        Number(!isClosingWithinHour(a.times[currentDay], currentTimePacific))
  );

  console.log(mobileViewHeight);
  const onMobileViewExpand = (isLeft) => {
    let newHeight;
    if (isLeft) {
      newHeight = mobileViewHeight === fullHeight ? partialHeight : noHeight;
    } else {
      newHeight =
        mobileViewHeight === partialHeight ? fullHeight : partialHeight;
    }
    setMobileViewHeight(newHeight);
  };

  return (
    <>
      {width <= 600 && (
        <div className="mobile-list-header">
          {mobileViewHeight !== noHeight ? (
            <Button
              onClick={() => onMobileViewExpand(true)}
              icon={<DownOutlined />}
            />
          ) : (
            <div className="ant-btn empty-button-placeholder"> </div>
          )}
          <Filter />
          {mobileViewHeight !== fullHeight ? (
            <Button
              onClick={() => onMobileViewExpand(false)}
              icon={<UpOutlined />}
            />
          ) : (
            <div className="ant-btn empty-button-placeholder"> </div>
          )}
        </div>
      )}
      <div
        id="scrollableDiv"
        className="side-list-container"
        style={{
          maxHeight: width <= 600 ? mobileViewHeight : "90%",
          padding: mobileViewHeight === noHeight ? "0px" : "10px 10px",
        }}
      >
        {width > 600 && <Filter />}
        <InfiniteScroll
          showLoader={isLoading || isError}
          dataLength={restaurants.length}
          hasMore={restaurants.length < 50}
          loader={
            isLoading && <Skeleton avatar paragraph={{ rows: 1 }} active />
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            // header={}
            locale={{
              emptyText: isError
                ? "We are having technical problems. Please retry later"
                : "No restaurants found, please try another location",
            }}
            dataSource={sortedRestaurantsByClosingTime}
            renderItem={(restaurant) => (
              <ListItem
                item={restaurant}
                isSelected={selectedRestaurant === restaurant.name}
              />
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default SideList;
