const formatDate = (time, isNextDay) => {
  // "01/01/2011" is just a placeholder to make sure new Date works
  return isNextDay
    ? new Date(`01/02/2011 ${time}`)
    : new Date(`01/01/2011 ${time}`);
};

export const isHappyHourNow = (userTime, restaurantTimes) => {
  if (!restaurantTimes) {
    return false;
  }
  const filteredRestaurants = restaurantTimes.filter((restaurantTimeSlot) => {
    const start = formatDate(restaurantTimeSlot[0], false);
    const end = formatDate(
      restaurantTimeSlot[1],
      restaurantTimeSlot[1] < restaurantTimeSlot[0]
    );
    const currentTime = formatDate(userTime.format("HH:mm"), false);
    return currentTime >= start && currentTime <= end;
  });
  return filteredRestaurants.length > 0;
};

export const isClosingWithinHour = (times, currentTime) => {
  if (!times) {
    return false;
  }
  const currentTimeHoursMinutesArray = currentTime.format("HH:mm").split(":");
  const filteredTimes = times.filter((timeSlot) => {
    if (timeSlot[0] > currentTime.format("HH:mm")) {
      return false;
    }
    const closingTime = timeSlot[1];
    const closingHoursMinutesArray = closingTime.split(":");
    // Need to convert closing and current time to minutes and take a diff of those
    // If diff < 60, means closing within an hour
    const closingTimeInMinutes =
      parseInt(closingHoursMinutesArray[0]) * 60 +
      parseInt(closingHoursMinutesArray[1]);
    const currentTimeInMinutes =
      parseInt(currentTimeHoursMinutesArray[0]) * 60 +
      parseInt(currentTimeHoursMinutesArray[1]);

    const timeDifference = closingTimeInMinutes - currentTimeInMinutes;
    return timeDifference < 60 && timeDifference > 0;
  });
  return filteredTimes.length > 0;
};

export const convertToAMPM = (time24) => {
  // Split the time string into hours and minutes
  console.log(time24);
  var time = time24.split(":");
  var hours = parseInt(time[0]);
  var minutes = parseInt(time[1]);

  // Determine whether it's AM or PM
  var suffix = hours >= 12 && hours < 24 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Add leading zero to single digit minutes
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct the new time string
  var time12 = hours + ":" + minutes + " " + suffix;

  return time12;
};
