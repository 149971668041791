import React from "react";

import "./style.css";

function About() {
  return (
    <div className="about-us-container">
      <div className="about-us-text">
        We are Happy-Bite and we are passionate about food. That’s why we bring
        this happy hour locater to you. Go grab your HAPPY BITE!
      </div>
    </div>
  );
}

export default About;
