import { combineReducers } from "redux";
import restaurantReducer from "./reducers/restaurantReducer";
import filterReducer from "./reducers/filterReducer";

const rootReducer = combineReducers({
  restaurants: restaurantReducer,
  filters: filterReducer,
});

export default rootReducer;
