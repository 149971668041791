import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import SideList from "components/SideList";
import { isClosingWithinHour, isHappyHourNow } from "utils/timeUtils";
import { SELECT_RESTAURANT } from "../../redux/constants";
import useWindowDimensions from "hooks/useWindowDimensions";

import "./style.css";

const containerStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
};

const center = {
  lat: 49.2827,
  lng: -123.1207,
};

var myStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];

const defaultMapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  fullscreenControl: false,
  streetViewControl: false,
  styles: myStyles,
};

function Home() {
  const { height, width } = useWindowDimensions();
  const [mapCenter, setMapCenter] = useState({
    lat: 49.2827,
    lng: -123.1207,
  });
  const restaurants = useSelector((state) => state.restaurants.restaurants);
  const currentTimePacific = useSelector((state) => state.filters.filterTime);
  const selectedRestaurant = useSelector(
    (state) => state.restaurants.selectedRestaurant
  );
  const isSelectedFromList = useSelector(
    (state) => state.restaurants.isSelectedFromList
  );
  const currentDay = new Date().getDay();
  const dispatch = useDispatch();

  const { isLoaded } = useJsApiLoader({
    id: process.env.REACT_APP_MAPS_PROJ,
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
  });

  useEffect(() => {
    if (isSelectedFromList) {
      const newLatLon = parseLatLon(
        restaurants.find((restaurant) => selectedRestaurant === restaurant.name)
          ?.location
      );
      setMapCenter(newLatLon);
    }
  }, [isSelectedFromList, selectedRestaurant]);

  const onMarkerClick = (restaurantName) => {
    dispatch({
      type: SELECT_RESTAURANT,
      payload: {
        selectedRestaurant: restaurantName,
        isSelectedFromList: false,
      },
    });
  };

  const parseLatLon = (restaurantLocation) => {
    const latLongArray = restaurantLocation
      .split(",")
      .map((location) => parseFloat(location));
    return { lat: latLongArray[0], lng: latLongArray[1] };
  };

  const selectMarkerIcon = (restaurant) => {
    let markerIcon = require("../../icons/grey-marker-bordered.png");

    if (isHappyHourNow(currentTimePacific, restaurant.times[currentDay])) {
      markerIcon = isClosingWithinHour(
        restaurant.times[currentDay],
        currentTimePacific
      )
        ? require("../../icons/yellow-marker-bordered.png")
        : require("../../icons/green-marker-bordered.png");
    }
    return markerIcon;
  };

  const restaurantsToMarkers = () => {
    return restaurants.map((restaurant) => {
      if (restaurant.times[currentDay] == null) {
        return;
      }
      const latLng = parseLatLon(restaurant.location);
      const isSelectedRestaurant = selectedRestaurant === restaurant.name;
      const markerIcon = selectMarkerIcon(restaurant);

      const iconSize = isSelectedRestaurant
        ? { height: 60, width: 48 }
        : { height: 40, width: 32 };

      return (
        <Marker
          onClick={() => onMarkerClick(restaurant.name)}
          zIndex={isSelectedRestaurant ? 1000 : 100}
          key={restaurant.name}
          label={{
            text: restaurant.name,
            color: "#333333",
            fontSize: "14px",
            fontWeight: "bold",
            className: isSelectedRestaurant
              ? "marker-label selected"
              : "marker-label",
          }}
          icon={{
            url: markerIcon,
            scaledSize: iconSize,
            strokeColor: "black",
            strokeWeight: 12,
            fillColor: "blue", // Fill color of the marker
            fillOpacity: 1, // Opacity of the marker
          }}
          id={restaurant.name}
          position={latLng}
        />
      );
    });
  };

  return (
    <div className="home-container">
      {isLoaded && (
        <GoogleMap
          options={defaultMapOptions}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={13}
        >
          {restaurantsToMarkers()}
        </GoogleMap>
      )}
      {width > 600 && <SideList />}
    </div>
  );
}

export default React.memo(Home);
