import { SET_FILTER_TIME } from "../constants";
import moment from "moment";

const currentTimePacific = moment(
  moment().utcOffset(-7).format("HH:mm"),
  "HH:mm"
); // Pacific corresponds to utc-8

const INITIAL_STATE = {
  filterTime: currentTimePacific,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_TIME:
      return {
        ...state,
        filterTime: action.payload.filterTime,
      };
    default:
      return state;
  }
};

export default reducer;
