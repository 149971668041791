import React, { useMemo, useRef } from "react";
import { List, Tag, Tooltip } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import {
  convertToAMPM,
  isClosingWithinHour,
  isHappyHourNow,
} from "utils/timeUtils";
import { SELECT_RESTAURANT } from "../../../redux/constants";

import "./style.css";
import { parseLatLon } from "utils/locationUtils";

const ListItem = ({ item, isSelected }) => {
  const myRef = useRef(null);
  const currentDay = new Date().getDay();
  const currentTimePacific = useSelector((state) => state.filters.filterTime);
  const closingWithinHour = useMemo(
    () => isClosingWithinHour(item.times[currentDay], currentTimePacific),
    [item, currentTimePacific]
  );
  const happyHourNow = isHappyHourNow(
    currentTimePacific,
    item.times[currentDay]
  );
  const dispatch = useDispatch();

  const onItemClick = () => {
    dispatch({
      type: SELECT_RESTAURANT,
      payload: { selectedRestaurant: item.name, isSelectedFromList: true },
    });
  };

  if (isSelected) {
    myRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  const mapTimes = (times) => {
    return times?.map((timeSlot, index) =>
      timeSlot.map((singleSlot) => convertToAMPM(singleSlot)).join(" - ")
    );
  };

  const selectColor = () => {
    if (happyHourNow) {
      return closingWithinHour ? "yellow" : "green";
    }
    return "grey";
  };

  const generateHappyHourHeading = () => {
    if (item.times[currentDay] == null || item.times[currentDay].length === 0) {
      return "No Happy Hours today.";
    } else if (!happyHourNow) {
      return "No Happy Hours at this time. Today's hours are:";
    } else if (closingWithinHour) {
      return "Happy Hour is over soon. Today's hours are:";
    } else {
      return "Today's Happy Hours are:";
    }
  };

  const latLng = parseLatLon(item.location);
  const encodedAddress = encodeURIComponent(item.address);
  const googleMapsUr = `https://www.google.com/maps?q=${encodedAddress}`;

  return (
    <List.Item
      key={item.name}
      className={`${isSelected && "selected-container"} ${selectColor()}`}
    >
      <div onClick={onItemClick} ref={myRef} className="list-item-container">
        <div className="list-item-info">
          <div className="list-item-header">
            <span>
              {happyHourNow && closingWithinHour && (
                <Tooltip
                  placement="bottom"
                  title="Happy hour will be over in less than an hour"
                >
                  <ExclamationCircleFilled />
                </Tooltip>
              )}{" "}
              <a href={item.menu} target="_blank" rel="noreferrer">
                {item.name}
              </a>
            </span>
            {/* <br /> */}
            <span className="menu-container">
              <a href={googleMapsUr} target="_blank" rel="noopener noreferrer">
                Open in Maps
              </a>
            </span>
          </div>
          {/* <br></br> */}
          <span className="happy-hour-text">{generateHappyHourHeading()}</span>
          <div>
            {mapTimes(item.times[currentDay])?.map((time) => (
              <Tag color="purple">{time}</Tag>
            ))}
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default ListItem;
