import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import Header from "./components/Header";
import Home from "./screens/Home";
import About from "./screens/About";
import SideList from "components/SideList";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Amplify, API } from "aws-amplify";

const { Header: AntdHeader, Footer, Content } = Layout;

Amplify.configure({
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_ENDPOINT_NAME,
        endpoint: process.env.REACT_APP_ENDPOINT,
      },
    ],
  },
});

function MyComponent() {
  const { height, width } = useWindowDimensions();

  return (
    <BrowserRouter>
      <Layout style={{ height: `${height}px` }}>
        <Header style={{ zIndex: 1 }} />
        <Content>
          {/* <button onClick={fetchDataFormDynamoDb}></button> */}
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Home />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </Content>
        {width > 600 && (
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#ffffff00",
              padding: "10px",
            }}
          >
            HappyBite ©2024 Created by HappyBite dev team.
          </Footer>
        )}
        {width <= 600 && <SideList />}
      </Layout>
    </BrowserRouter>
  );
}

export default React.memo(MyComponent);
