import React from "react";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import { useLocation } from "react-router-dom";

const logo = require("../../icons/logo.png");

function Header() {
  const location = useLocation();

  const imageAlternativeText = "Happy Bite Logo";

  return (
    <Layout.Header className="header">
      <Link to="/" key="home">
        <img src={logo} className="header-logo" alt={imageAlternativeText} />
      </Link>
      <Menu selectedKeys={[location.pathname]} mode="horizontal">
        <Menu.Item key="/">
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="/about">
          <Link to="/about">About</Link>
        </Menu.Item>
      </Menu>
    </Layout.Header>
  );
}

export default Header;
